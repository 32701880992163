@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~mdbootstrap/css/mdb.min.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~select2/dist/css/select2.css";

.loading {
  width:100%;
  position:absolute;
  background: rgba(255, 255, 255, 0.8);
  z-index:99999;

  &.loading-login {
    position: relative;
  }

  .loading-img {
    i {
      font-size: 80px;
      color: #FF5722;
    }
    span {
      font-size: 50px;
      color: #FF5722;
    }
  }
}

body {
  background: #F3F3F4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dataTables_filter {
  display: none;
}

.dataTables_length {
  padding-top: 15px;
}

.bg-red {
  background: red !important;
}

.bg-yellow {
  background: yellow !important;
}

.bg-green {
  background: green !important;
}

.bg-orange {
  background: orange !important;
}

.btn-orange {
  background-color: #FF5722 !important;
}

.btn-grey {
  background-color: #9E9E9E !important;
}

.saveinput {
  border: none;
  background-color: initial;

  &:focus {
    outline: auto 4px;
    background-color: #FFFFFF;
  }
}

.paginate_button a:hover,
.paginate_button.next a:hover,
.paginate_button.active a {
  background-color: #FF5722 !important;
  color: white !important;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 1920px;
}